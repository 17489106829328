$(document).ready(function () {
    changeLanguage()
    AOS.init({
        disable: 'mobile',
        duration: 1300,
        offset: 300
    });

    $(window).on('scroll', function () {
        $('header').toggleClass('scrolling', $(window).scrollTop() > 0)
        $('.tong-quan-nav').toggleClass('scrolling', $(window).scrollTop() > 249)
    })

    $('#imageMap').maphilight({
        fillColor: '82ba41',
        fillOpacity: 0.7,
        stroke: false,
        groupBy: 'group'
    })

    $('.image-map area').each(function () {
        let index = $(this).data('index')
        $(this).qtip({
            content: {
                text: $(".tooltip-container-1 .map-tooltip[data-index='" + index + "']").clone()
            },
            position: {
                container: $('div.image-map'),
                my: 'bottom center',
                at: 'top center',
            }
        })
    });

    $('.count-up').countUp({
        'time': 2000,
        'delay': 10
    });
    App.init()
    initSlider()
    $('.tabslet').tabslet()
    socialShare()
    closeMenu()
    scrollNavTQ()
    toggleNavTQ()
    $('img[usemap]').rwdImageMaps()
    resetForm()
    changePlaceholder()
    deleteSpan()
    toogleButtonImgFixed()
});

function resetForm(){
    $('footer .subscribe-form .frm-btn .frm-btn-reset').on('click', function(){
        console.log(1)
        setTimeout(() => {
            
            $('footer .subscribe-form .reset-focus input').focus()
            $('footer .subscribe-form .fa-exclamation-triangle').css('display', 'none')
        }, 100);
    })
}

function toggleNavTQ(){
    $('.tong-quan-nav .category').on('click', function(){
        $(this).toggleClass('active')
        $('.tong-quan-nav .list-nav').slideToggle()
    })
}
function closeMenu(){
    $('.homepage header .menu-wrapper .register-subscribe').on('click', function(e){
        e.preventDefault()
        $('header .menu-wrapper .menu-toggle').removeClass('uk-open').attr('aria-expanded', 'false')
        $('header .menu-wrapper .menu-dropdown').removeClass('uk-open')
        $('html, body').animate({
            scrollTop: $('#register-news').offset().top
        }, 3000)
        setTimeout(() => {
            $('header .menu-wrapper .menu-toggle').on('click', function(){
                $(this).toggleClass('uk-open')
                $('header .menu-wrapper .menu-dropdown').toggleClass('uk-open')
            })
        }, 1000);
    })
    
}
const headerRightMapping = new MappingListener({
    selector: ".header-right",
    mobileWrapper: ".menu-dropdown",
    mobileMethod: "prependTo",
    desktopWrapper: ".header-left",
    desktopMethod: "insertAfter",
    breakpoint: 1025
}).watch();


const App = {
    SetBackground: () => {
        $('[setBackground]').each(function () {
            var background = $(this).attr('setBackground')
            $(this).css({
                "background-image": "url(" + background + ")",
                "background-size": "cover",
                "background-position": "center center"
            })
        })
        $('[setBackgroundRepeat]').each(function () {
            var background = $(this).attr('setBackgroundRepeat')
            $(this).css({
                "background-image": "url(" + background + ")",
                "background-repeat": "repeat"
            })
        })
    },
    equalHeightElement: el => {
        let height = 0;
        let thisHeight = 0;
        $(el).each(function () {
            thisHeight = $(this).height();
            if (thisHeight > height) {
                height = thisHeight;
            }
        });
        $(el).height(height)
    },
    // initLazyLoad: () => {
    //     return new LazyLoad({
    //         elements_selector: ".lazyload"
    //     });
    // },
    ScrollTo: y => {
        $('html, body').animate({
            scrollTop: y
        }, 1000)
    },
    init: () => {
        App.SetBackground()
        // App.initLazyLoad()
    }
}

function initSlider() {
    let bannerPKSP = new Swiper('.phan-khu-san-pham-2 .swiper-container', {
        loop: true,
        speed: 1000,
        autoplay: {
            delay: 4500
        },
        navigation: {
            nextEl: '.phan-khu-san-pham-2 .button-next',
            prevEl: '.phan-khu-san-pham-2 .button-prev',
        },
    })
    let bannerPKSPK = new Swiper('.phan-khu-san-pham-4 .swiper-container', {
        loop: true,
        speed: 1000,
        autoplay: {
            delay: 4500
        },
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
            nextEl: '.phan-khu-san-pham-4 .button-next',
            prevEl: '.phan-khu-san-pham-4 .button-prev',
        },
        breakpoints: {
            576: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1025: {
                slidesPerView: 3,
                
            }
        }
    })
    let slideNgoaiKhu = new Swiper('.ngoai-khu-1 .swiper-container', {
        loop: true,
        speed: 1000,
        autoplay: {
            delay: 4500
        },
        navigation: {
            nextEl: '.ngoai-khu-1 .button-next',
            prevEl: '.ngoai-khu-1 .button-prev',
        },
    })
    let slideNewsother = new Swiper('.tin-tuc-ct .swiper-container', {
        // loop: true,
        speed: 1000,
        // autoplay: {
        //     delay: 4500
        // },
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
            nextEl: '.tin-tuc-ct .button-next',
            prevEl: '.tin-tuc-ct .button-prev',
        },
        breakpoints: {
            576: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1025: {
                slidesPerView: 3,
                
            },
            1200: {
                slidesPerView: 4,
                
            }
        }
    })
}
function socialShare() {
    $("#shareSocial").jsSocials({
        showLabel: false,
        showCount: false,
        shares: [{
                share: "facebook",
                logo: "fab fa-facebook-f"
            },{
                share: "twitter",
                logo: "fab fa-twitter"
            }
            
        ],

    });
}
function scrollNavTQ(){
    $('.tong-quan-nav .list-nav li a').on('click', function () {
        if($(window).width()<577){
            $('.tong-quan-nav .category').removeClass('active')
            $('.tong-quan-nav .list-nav').slideUp()
        }
        $('.tong-quan-nav .list-nav li a').removeClass('active')
        $(this).addClass('active')
        let html = $(this).html()
        console.log(html)
        let active = $(this).attr('href')
        let active2 =  active.replace(/[^\w\s]/gi, '')
        let offset = $('section[id=' + active2 + ']').offset().top - $('header').height() - $('.tong-quan-nav').height()
        $('.tong-quan-nav .category p').html(html)
        $("html, body").animate({
            scrollTop: offset
        }, 1000);
    })
}
function changePlaceholder(){
    $('.contact-page .wrap-form .frm-captcha-input input').attr('placeholder', 'Mã hiển thị')
    $('footer .subscribe-form .frm-captcha .frm-captcha-input input').attr('placeholder', 'Mã hiển thị')
    $('footer .subscribe-form .frm-btn input.frm-btn-submit').attr('onclick', 'atag("send", "conversion")')
}
function deleteSpan(){
    $('footer .subscribe-form .frm-btn input.frm-btn-reset').on('click', function(){
        $('footer .subscribe-form .form-group span').css('display', 'none')
    })
}
function changeLanguage(){

    // if(window.location.href.indexOf("/en-US") > -1){
    //     $('#ctl00_mdl146_ctl00_txtCaptcha').attr('placeholder', 'Display code')
    // }
    $('footer .subscribe-form .frm-captcha .frm-captcha-input input').attr('placeholder', '')
    $('#ctl00_mainContent_ctl04_txtCaptcha').attr('placeholder', '')
    if (window.location.href.indexOf("/en-US") > -1) {
        $('footer .footer-child .footer-wrapper .list-address').addClass('mg-8')
        
        $('.contact-page .wrap-form .frm-captcha .frm-captcha-input').addClass('holder-0')
        $('.footer .subscribe-form .frm-captcha .frm-captcha-input').addClass('holder-0')
        $('.contact-page .wrap-form .frm-captcha .frm-captcha-input input::placeholder').css('font-size', '0')
    }
    if (window.location.href.indexOf("/en-us") > -1) {
        $('#modal-search .uk-search input').attr('placeholder', 'Search')
        $('.footer .subscribe-form .frm-captcha .frm-captcha-input').addClass('holder-0')
    }
}
function toogleButtonImgFixed(){
    $('.fixed-image-button').on('click', function(){
        $(this).toggleClass('active')
        $('.fixed-image').toggleClass('active')
    })
}